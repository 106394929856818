import React from "react";
import { Switch, Route } from "react-router-dom";

import MainNavigation from "../shared/components/Navigation/MainNavigation";
import Footer from "../shared/components/Navigation/Footer";
import AdminRoute from "../shared/helpers/AdminRoute";

import Home from "../home/pages/Home";
import StandPage from "../stand/pages/StandPage";
import LotteryPage from "../lottery/pages/LotteryPage";
import VisitedPage from "../visited/pages/VisitedList";

import BackendHome from "../backend/home/pages/Home";
import BackendNewStand from "../backend/stands/pages/NewStand";
import BackendStandsList from "../backend/stands/pages/StandsList";
import BackendUpdateStand from "../backend/stands/pages/UpdateStand";
import BackendUsers from "../backend/user/pages/Users";
import BackendAnalytics from "../backend/analytic/pages/Analytics";
import BackendLottery from "../backend/lottery/pages/Lottery";
import BackendCupons from "../backend/cupons/pages/Cupons";
import BackendQrcode from "../backend/qr/pages/Qrcode";
import BackendBulkUpload from "../backend/bulk/pages/BulkUpload";



const Routes = () => {
    return (
        <React.Fragment>
            <MainNavigation />
            <main>
                <Switch>
                    <Route path="/" exact>
                        <Home />
                    </Route>
                    <Route path="/login/:idpassword" exact>
                        <Home />
                    </Route>
                    <Route exact path="/qrs/:standName">
                        <StandPage />
                    </Route>
                    <Route exact path="/lottery/:lotteryName">
                        <LotteryPage />
                    </Route>
                    <Route exact path="/visited">
                        <VisitedPage />
                    </Route>
                    <AdminRoute exact path="/backend" component={BackendHome} />
                    <AdminRoute exact path="/backend/stands/new" component={BackendNewStand} />
                    <AdminRoute exact path="/backend/stands" component={BackendStandsList} />
                    <AdminRoute exact path="/backend/stands/:standName" component={BackendUpdateStand} />
                    <AdminRoute exact path="/backend/users" component={BackendUsers} />
                    <AdminRoute exact path="/backend/analytic/:standName" component={BackendAnalytics} />
                    <AdminRoute exact path="/backend/lottery" component={BackendLottery} />
                    <AdminRoute exact path="/backend/cupons/:standName" component={BackendCupons} />
                    <AdminRoute exact path="/backend/qr/:standName" component={BackendQrcode} />
                    <AdminRoute exact path="/backend/bulk" component={BackendBulkUpload} />


                </Switch>
            </main>
            <Footer />
        </React.Fragment>
    );
};

export default Routes;
