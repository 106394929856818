import React, { useState, useRef, useEffect } from "react";
import QRCodeStyling from "qr-code-styling";

import { Formik, useFormikContext } from "formik";

import { Accordion, Modal } from "react-bootstrap";
import { HexColorPicker } from "react-colorful";
import { IoCloseSharp } from "react-icons/io5";

import { useParams } from "react-router-dom";
import BannerStands from '../../stands/assets/Banner5.png';
// import { useHttpClient } from "../../../shared/hooks/http-hook";
// import { AuthContext } from "../../../shared/context/AuthContext";
import Button from "../../../shared/components/FormElements/Button";
import { BsFillArrowLeftCircleFill, BsFillSaveFill } from "react-icons/bs";

import {
    GenericForm,
    FormInputComponent,
} from "../../../shared/components/FormElements/GenericForm";
import "./Qrcode.css";

const qrCode = new QRCodeStyling();

const Qrcode = () => {
    // const auth = useContext(AuthContext);
    // const { sendRequest, error } = useHttpClient();

    const standName = useParams().standName;
    const [url, setUrl] = useState(`../../../qrs/${standName}`);

    const [fileExt, setFileExt] = useState("png");
    const [image, setImage] = useState(null);
    const [color, setColor] = useState(null);
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    const [dot, setDot] = useState(null);
    const [corner, setCorner] = useState(null);
    const ref = useRef(null);

    const [QRCode, setQRCode] = useState({
        data: `${process.env.REACT_APP_LOCAL_URL}/qrs/${standName}`,
        image: "",
        dotsOptions: {
            color: "#4267b2",
            type: "rounded",
        },
        cornersSquareOptions: {
            type: "extra-rounded",
        },
        width: 300,
        height: 300,
    });

    const [colorPickerVariable, setColorPickerVariable] = useState(null);
    const [colorPickerField, setColorPickerField] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);

    const showHexPickerHandler = (variable, field) => {
        setShowModal(true);
        setColorPickerVariable(variable);
        setColorPickerField(field);
    };

    useEffect(() => {
        qrCode.append(ref.current);
    }, []);

    const formRef = useRef();

    useEffect(() => {
        qrCode.update({
            data: url,
            image: image,
            dotsOptions: {
                color: color,
                type: dot,
            },
            cornersSquareOptions: {
                type: corner,
            },
            width: width,
            height: height,
        });
    }, [color, dot, height, image, url, width, corner]);

    const onImageChange = (event) => {
        event.preventDefault();
        setImage(URL.createObjectURL(event.target.files[0]));
    };

    const ContextListener = () => {
        const { values } = useFormikContext();

        React.useEffect(() => {
            setQRCode(values);
            setUrl(values.data);
            setColor(values.dotsOptions.color);
            setWidth(values.width);
            setHeight(values.height);
            setCorner(values.cornersSquareOptions.type);
            setDot(values.dotsOptions.type);
            setFileExt(values.fileExt);
        }, [values]);
        return null;
    };

    // useEffect(() => {
    //     QRFetch();
    // }, [QRFetch]);

    if (QRCode) {
        return (
            <React.Fragment>
                <Formik
                    initialValues={{
                        data: QRCode.data,
                        image: QRCode.image,
                        dotsOptions: QRCode.dotsOptions,
                        cornersSquareOptions: QRCode.cornersSquareOptions,
                        width: QRCode.width,
                        height: QRCode.height,
                        fileExt: "png",
                    }}
                    validateOnMount
                    innerRef={formRef}
                    onSubmit={async (values) => {
                        qrCode.download({
                            name: standName,
                            extension: fileExt,
                        });
                       
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <React.Fragment>
                            <Modal show={showModal} onHide={handleClose}>
                                <Modal.Header>
                                    <button
                                        onClick={handleClose}
                                        className="generic-modal__close"
                                    >
                                        <IoCloseSharp />
                                    </button>
                                    <Modal.Title>Selector de Color</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <HexColorPicker
                                        color={colorPickerVariable}
                                        onChange={(color) =>
                                            setFieldValue(
                                                colorPickerField,
                                                color
                                            ) && setColorPickerVariable(color)
                                        }
                                    />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        className="main-button--squared"
                                        onClick={handleClose}
                                    >
                                        Cerrar
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <img src={BannerStands} width="100%" alt="" />
                            <div className="generic-form__button-block"> 
                                                <Button
                                                    to="/backend/stands"
                                                    className="stands-list__back-button"
                                                >
                                                    <BsFillArrowLeftCircleFill className="standlist-imageButton__iconblack" />
                                                    Atrás
                                                </Button>
                            </div>

                            <GenericForm>
                                <div className="p-3" ref={ref} />
                                <Accordion flush>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <h3>Edición</h3>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <FormInputComponent
                                                className="d-none"
                                                formInputLayout="column"
                                                fieldType="text"
                                                fieldName="data"
                                                fieldDescription="URL"
                                                fieldPlaceholder=""
                                            />
                                            <div className="generic-form__input-block qr-file__container">
                                            <label>
                                                Imagen central del QR
                                            </label>
                                            <input
                                                className="generic-form__input-block generic-form__input-block--column qr-file__input"
                                                id="upload"
                                                type="file"
                                                accept="image/*"
                                                onChange={onImageChange}
                                            />
                                            </div>
                                           
                                            {/* <FormInputComponent
                                                formInputLayout="column"
                                                fieldType="file"
                                                fieldName="image"
                                                fieldDescription="Imagen central del QR"
                                                fieldPlaceholder=""
                                                fileUpload={true}
                                                setFieldValue={setFieldValue}
                                                onChange={onImageChange}
                                            /> */}

                                            <FormInputComponent
                                                formInputLayout="column"
                                                fieldType="text"
                                                fieldName="dotsOptions.color"
                                                fieldDescription="Color del QR"
                                                fieldPlaceholder="#000000"
                                                colorPicker={true}
                                                colorPickerHandler={() =>
                                                    showHexPickerHandler(
                                                        values.dotsOptions
                                                            .color,
                                                        "dotsOptions.color"
                                                    )
                                                }
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setColor(e.target.value);
                                                }}
                                            />

                                            <div className="generic-form__input-block--grid-nomargin">
                                                <FormInputComponent
                                                    formInputLayout="column"
                                                    fieldType="select"
                                                    fieldAs="select"
                                                    fieldSelect={[
                                                        {
                                                            value: "square",
                                                            text: "Square",
                                                        },
                                                        {
                                                            value: "dots",
                                                            text: "Dots",
                                                        },
                                                        {
                                                            value: "rounded",
                                                            text: "Rounded",
                                                        },
                                                        {
                                                            value: "extra-rounded",
                                                            text: "Extra Rounded",
                                                        },
                                                        {
                                                            value: "classy",
                                                            text: "Classy",
                                                        },
                                                        {
                                                            value: "classy-rounded",
                                                            text: "Classy Rounded",
                                                        },
                                                    ]}
                                                    fieldName="dotsOptions.type"
                                                    fieldDescription="Tipo de diseño del QR"
                                                    fieldPlaceholder=""
                                                />
                                                {/* <select
                                                onChange={onCornerChange}
                                                value={corner}
                                            >
                                                <option value="">None</option>
                                                <option value="square">
                                                    Square
                                                </option>
                                                <option value="dot">Dot</option>
                                                <option
                                                    value="extra-rounded"
                                                    selected=""
                                                >
                                                    Extra rounded
                                                </option>
                                            </select> */}
                                                <FormInputComponent
                                                    formInputLayout="column"
                                                    fieldType="select"
                                                    fieldAs="select"
                                                    fieldSelect={[
                                                        {
                                                            value: "square",
                                                            text: "Square",
                                                        },
                                                        {
                                                            value: "dot",
                                                            text: "Dot",
                                                        },
                                                        {
                                                            value: "extra-rounded",
                                                            text: "Extra Rounded",
                                                        },
                                                    ]}
                                                    fieldName="cornersSquareOptions.type"
                                                    fieldDescription="Tipo de esquinas del QR"
                                                    fieldPlaceholder=""
                                                />
                                            </div>
                                            <div className="generic-form__input-block--grid-nomargin">
                                                <FormInputComponent
                                                    formInputLayout="column"
                                                    fieldType="text"
                                                    fieldName="width"
                                                    fieldDescription="Ancho (en px)"
                                                    fieldPlaceholder=""
                                                />
                                                <FormInputComponent
                                                    formInputLayout="column"
                                                    fieldType="text"
                                                    fieldName="height"
                                                    fieldDescription="Alto (en px)"
                                                    fieldPlaceholder=""
                                                />
                                            </div>

                                            <FormInputComponent
                                                formInputLayout="column"
                                                fieldType="select"
                                                fieldAs="select"
                                                fieldSelect={[
                                                    {
                                                        value: "png",
                                                        text: "PNG",
                                                    },
                                                    {
                                                        value: "jpeg",
                                                        text: "JPEG",
                                                    },
                                                    {
                                                        value: "webp",
                                                        text: "WEBP",
                                                    },
                                                ]}
                                                fieldName="fileExt"
                                                fieldDescription="Extensión de la imagen"
                                                fieldPlaceholder=""
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>

                                 
                                </Accordion>
                                <div className="generic-form__footer">
                                   
                                        <Button
                                            className=""
                                            type="submit"
                                            status="active"
                                        >
                                        <BsFillSaveFill className="standlist-imageButton__iconblack" />
                                            Descargar QR
                                        </Button>
                                     
                                </div>
                                <ContextListener />
                            </GenericForm>
                        </React.Fragment>
                    )}
                </Formik>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <div>Cargando</div>
            </React.Fragment>
        );
    }

};

export default Qrcode;
