import React from "react";

import "./ComoFunciona.css";

import iconoDisfrutar from "../assets/IconoDisfrutar.png";
import iconoElegir from "../assets/IconoElegir.png";
import iconoGanar from "../assets/IconoGanar.png";
import iconoPostularse from "../assets/IconoPostularse.png";

const ComoFunciona = () => {
    return (
        <div className="disclaimer-banner">
            <h3 className="disclaimer-banner__title">¿Cómo funciona?</h3>
            <div className="disclaimer-banner__content">
                <div className="disclaimer-banner__step">
                    <div className="disclaimer-banner__circle">
                        <img
                            className="disclaimer-banner__circle-icon"
                            src={iconoElegir}
                            alt=""
                        />
                    </div>
                    <div className="disclaimer-banner__step-texts">
                        <h4 className="disclaimer-banner__step-title">
                            Creá
                        </h4>
                        <p className="disclaimer-banner__step-text">
                            Creá el lookup de tu tienda
                        </p>
                    </div>
                </div>
                <i className="disclaimer-banner__arrow"></i>
                <div className="disclaimer-banner__step">
                    <div className="disclaimer-banner__circle">
                        <img
                            className="disclaimer-banner__circle-icon"
                            src={iconoPostularse}
                            alt=""
                        />
                    </div>
                    <div className="disclaimer-banner__step-texts">
                        <h4 className="disclaimer-banner__step-title">
                            Compartí
                        </h4>
                        <p className="disclaimer-banner__step-text">
                            Compartí el QR para que puedan entrar a tu Tienda
                        </p>
                    </div>
                </div>
                <i className="disclaimer-banner__arrow"></i>
                <div className="disclaimer-banner__step">
                    <div className="disclaimer-banner__circle">
                        <img
                            className="disclaimer-banner__circle-icon"
                            src={iconoGanar}
                            alt=""
                        />
                    </div>
                    <div className="disclaimer-banner__step-texts">
                        <h4 className="disclaimer-banner__step-title">Promocioná</h4>
                        <p className="disclaimer-banner__step-text">
                            Generá destacados y promociones para atraer usuarios
                        </p>
                    </div>
                </div>
                <i className="disclaimer-banner__arrow"></i>
                <div className="disclaimer-banner__step">
                    <div className="disclaimer-banner__circle">
                        <img
                            className="disclaimer-banner__circle-icon"
                            src={iconoDisfrutar}
                            alt=""
                        />
                    </div>
                    <div className="disclaimer-banner__step-texts">
                        <h4 className="disclaimer-banner__step-title">
                            Analítica
                        </h4>
                        <p className="disclaimer-banner__step-text">
                            Obtene toda la información de quienes visitaron tu Tienda.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComoFunciona;
