import React from "react";
import { Formik, Form, Field, ErrorMessage, useField } from "formik";
import { useHttpClient } from "../../shared/hooks/http-hook";
import * as Yup from "yup";
import "./HomePageForm.css";

import Button from "../../shared/components/FormElements/Button";

const HomePageSchema = Yup.object().shape({
    firstName: Yup.string().max(24, "Demasiado largo").required("Requerido"),
    lastName: Yup.string().max(24, "Demasiado largo").required("Requerido"),
    company: Yup.string().max(200, "Demasiado largo").required("Requerido"),
    email: Yup.string().email("Correo inválido").required("Requerido"),
    telephone: Yup.number().min(8, "No es válido").required("Requerido"),
    concern: Yup.string()
        .oneOf(
            ["alimentos", "ganaderia", "textil", "otro"],
            "Categoría Inválida"
        )
        .required("Requerido"),
    description: Yup.string().max(300, "Demasiado largo").required("Requerido"),
});

const MyTextArea = ({ ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field] = useField(props);
    return (
        <>
            <textarea
                className="homepage-form__form-input"
                {...field}
                {...props}
            />
        </>
    );
};
const MySelect = ({ ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field] = useField(props);
    return (
        <>
            <select
                className="homepage-form__select-input"
                {...field}
                {...props}
            />
        </>
    );
};

const HomePageForm = (props) => {
    const { sendRequest, clearError } = useHttpClient();
    return (
        <Formik
            initialValues={{
                firstName: "",
                lastName: "",
                company: "",
                email: "",
                telephone: "",
                concern: "",
                description: "",
            }}
            validationSchema={HomePageSchema}
            onSubmit={async (values, { setSubmitting }) => {
                clearError();
                try {
                    const responseDataMail = await sendRequest(
                        `${process.env.REACT_APP_API_URL}/api/mails/sendmail`,
                        "POST",
                        JSON.stringify({
                            firstName: values.firstName,
                            email: "jmanuel.martin@onlife.com.ar",
                            message: `Nombre: ${values.firstName} ${values.lastName} <br>Email:  <br>Consulta: Desea ser Expositor <br>Empresa: ${values.company} <br>Teléfono: ${values.telephone} <br>Productos/Servicos: ${values.concern} <br>Descripción: ${values.description}`,
                        }),
                        {
                            "Content-Type": "application/json",
                        }
                    );
                    if (responseDataMail.approved === "sent") {
                        //console.log(responseData.userId,responseData.token);
                        //console.log("mail enviado");
                    }
                } catch (err) {}
            }}
        >
            {({ isValid, dirty }) => (
                <div className="homepage-form__background">
                    <h2 className="homepage-form__title--big">
                        Completá los datos
                    </h2>
                    <Form className="homepage-form">
                        <div className="homepage-form__content">
                            <div className="homepage-form__input-block homepage-form__input-block--grid">
                                <div className="homepage-form__input-block homepage-form__input-block--column">
                                    <div className="homepage-form__error-placeholder homepage-form__error-placeholder--column">
                                        <ErrorMessage
                                            name="firstName"
                                            component="div"
                                            className="homepage-form__validation-error"
                                        />
                                    </div>
                                    <Field
                                        type="firstName"
                                        name="firstName"
                                        placeholder="Nombre"
                                        className="homepage-form__form-input homepage-form__form-input--inline"
                                    />
                                </div>
                                <div className="homepage-form__input-block homepage-form__input-block--column">
                                    <div className="homepage-form__error-placeholder homepage-form__error-placeholder--column">
                                        <ErrorMessage
                                            name="lastName"
                                            component="div"
                                            className="homepage-form__validation-error"
                                        />
                                    </div>
                                    <Field
                                        type="lastName"
                                        name="lastName"
                                        placeholder="Apellido"
                                        className="homepage-form__form-input homepage-form__form-input--inline"
                                    />
                                </div>
                            </div>
                            <div className="homepage-form__input-block">
                                <div className="homepage-form__error-placeholder">
                                    <ErrorMessage
                                        name="company"
                                        component="div"
                                        className="homepage-form__validation-error"
                                    />
                                </div>
                                <Field
                                    type="company"
                                    name="company"
                                    placeholder="Empresa"
                                    className="homepage-form__form-input"
                                />
                            </div>

                            <div className="homepage-form__input-block">
                                <div className="homepage-form__error-placeholder">
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="homepage-form__validation-error"
                                    />
                                </div>
                                <Field
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    className="homepage-form__form-input"
                                />
                            </div>
                            <div className="auth-form__input-block">
                                <div className="auth-form__error-placeholder">
                                    <ErrorMessage
                                        name="telephone"
                                        component="div"
                                        className="auth-form__validation-error"
                                    />
                                </div>
                                <Field
                                    type="telephone"
                                    name="telephone"
                                    placeholder="(+54)"
                                    className="auth-form__form-input"
                                />
                            </div>
                            <div className="homepage-form__input-block">
                                <div className="homepage-form__error-placeholder">
                                    <ErrorMessage
                                        name="concern"
                                        component="div"
                                        className="homepage-form__validation-error"
                                    />
                                </div>
                                <MySelect name="concern">
                                    <option value="">
                                        Producto o servicio que ofrece
                                    </option>
                                    <option value="alimentos">Alimentos</option>
                                    <option value="ganaderia">Ganaderia</option>
                                    <option value="textil">Textil</option>
                                    <option value="otro">Otro</option>
                                </MySelect>
                            </div>
                            <div className="homepage-form__input-block">
                                <div className="homepage-form__error-placeholder">
                                    <ErrorMessage
                                        name="description"
                                        component="div"
                                        className="homepage-form__validation-error"
                                    />
                                </div>
                                <MyTextArea
                                    name="description"
                                    rows="6"
                                    placeholder="Contanos a que se dedica tu empresa ..."
                                />
                            </div>
                        </div>

                        <div className="homepage-form__footer">
                            <div className="homepage-form__button-block--grid">
                                <div className="homepage-form__button-block--column">
                                    <Button
                                        className="main-button--squared-bigger main-button--80-width"
                                        status={
                                            dirty && isValid
                                                ? "active"
                                                : "inactive"
                                        }
                                        type="submit"
                                        onClick={props.hide}
                                    >
                                        Enviar
                                    </Button>
                                </div>
                                <div className="homepage-form__button-block--column">
                                    <Button
                                        className="main-button--squared-bigger main-button--80-width"
                                        status={
                                            dirty && isValid
                                                ? "active"
                                                : "inactive"
                                        }
                                        type="submit"
                                        onClick={props.hide}
                                    >
                                        Whatsapp
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
};

export default HomePageForm;
