import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/AuthContext";
import BannerCategory from "../../shared/components/UIElements/BannerCategory";

import "./VisitedList.css";

const VisitedList = () => {
    const auth = useContext(AuthContext);
    const [loadedStands, setLoadedStands] = useState();
    const { isLoading, sendRequest } = useHttpClient();
    useEffect(() => {
        const fetchStands = async () => {
            if(auth.token){
                try {
                    const responseData = await sendRequest(
                        `${process.env.REACT_APP_API_URL}/api/stands/`,
                        "GET",
                        null,
                        { Authorization: "Bearer " + auth.token }
                    );
                    setLoadedStands(responseData.stands);
                } catch (err) {}
            }
        };
        fetchStands();
    }, [sendRequest, auth.token]);

    return (
        <React.Fragment>
            <BannerCategory title="Stands Visitados" />
            <div className="visited-list-container">
                {!isLoading &&
                    loadedStands &&
                    loadedStands.map((stand) => {
                        if (stand.active !== "Eliminado") {
                            return (
                                <div
                                    className="visited-item-container"
                                    key={stand._id}
                                >
                                    <div className="visited-list-image-container">
                                        <img
                                            className="visited-lists-image"
                                            src={`${process.env.REACT_APP_API_URL}/${stand.navIcon}`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="visited-list-info-container">
                                        <div className="visited-list-info__title">
                                            {stand.standName}
                                        </div>
                                        <div className="visited-list-info__button">
                                            <Link
                                                to={`../../../qrs/${stand.standName}`}
                                            >
                                                Ingresar
                                            </Link>
                                            
                                        </div>
                                    </div>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
            </div>
        </React.Fragment>
    );
};

export default VisitedList;
